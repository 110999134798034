import gsap from 'gsap'
import { qs, qsa } from '../utils'

export const animateScroll = (animation) => {
  switch (animation) {
    case 'about':
      const about = gsap.timeline({
        scrollTrigger: {
          trigger: '.about',
          start: 'top top+=55%',
          end: 'bottom bottom',
          scrub: true,
          once: true,
        },
      })

      const about2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.about',
          start: 'top top+=30%',
          end: 'bottom bottom',
          //scrub: true,
        },
      })

      about.fromTo(
        '.about .stripe',
        { scaleY: 0 },
        { scaleY: 1, stagger: 0.05 },
      )

      about2.from(
        '.about .word-0',
        {
          duration: 0.6,
          autoAlpha: 0,
          y: 40,
          stagger: 0.05,
        },
        '-=0.5',
      )

      break

    case 'services':
      const serviceItems = qsa('.service-item')

      const service = gsap.timeline({
        scrollTrigger: {
          trigger: '.services',
          start: 'top top+=55%',
          end: 'bottom bottom',
          scrub: true,
          once: true,
        },
      })

      const service2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.services',
          start: 'top top+=30%',
          end: 'bottom bottom',
        },
      })

      // service2.to('.services .box-bg', { duration: 0.5, scaleY: 0 })
      service2.from('.services .side-bg', { duration: 0.5, scaleY: 0 })

      service2.from(
        '.services .-text-vertical',
        {
          duration: 0.8,
          autoAlpha: 0,
          y: 20,
          stagger: 0.2,
        },
        '-=0.8',
      )

      serviceItems.forEach((item, i) => {
        const chars = qsa('.char-0', item)

        service2.from(
          chars,
          { duration: 0.5, y: '101%', stagger: 0.015, ease: 'power2.inOut' },
          i * 0.15,
        )
      })

      service.fromTo(
        '.services .stripe',
        { scaleY: 1 },
        { scaleY: 0, stagger: 0.07 },
      )

      break

    case 'clients':
      const clients = gsap.timeline({
        scrollTrigger: {
          trigger: '.clients',
          start: 'top top+=55%',
          end: 'bottom bottom',
          scrub: true,
          once: true,
        },
      })

      const clients2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.clients',
          start: 'top top+=30%',
          end: 'bottom bottom',
        },
      })

      clients.fromTo(
        '.clients .stripe',
        { scaleY: 1 },
        { scaleY: 0, stagger: 0.05 },
      )

      clients2.from('.clients .clients-category', {
        duration: 0.6,
        y: 20,
        autoAlpha: 0,
        stagger: 0.4,
      })

      clients2.from(
        '.clients .clients-item',
        {
          duration: 0.8,
          y: 20,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=1.2',
      )

      break

    case 'work':
      const work = gsap.timeline({
        scrollTrigger: {
          trigger: '.work',
          start: 'top top+=55%',
          end: 'bottom bottom+=40%',
          scrub: true,
          once: true,
        },
      })

      const work2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.work',
          start: 'top top+=30%',
          end: 'bottom bottom',
        },
      })

      work.fromTo('.work .stripe', { scaleY: 1 }, { scaleY: 0, stagger: 0.05 })

      work2.from('.work .line-0', { autoAlpha: 0, y: 10, stagger: 0.1 })

      work2.from('.work .item-name', {
        duration: 0.8,
        y: 40,
        autoAlpha: 0,
        stagger: 0.1,
      })

      work2.from(
        '.work .item-nr',
        {
          duration: 0.8,
          y: 40,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=1.2',
      )

      work2.from('.work img', { autoAlpha: 0, scale: 1.15, y: '20%' }, '-=1.6')

      break

    case 'footer':
      const footer = gsap.timeline({
        scrollTrigger: {
          trigger: '.footer',
          start: 'top top+=55%',
          end: 'bottom bottom',
          scrub: true,
          once: true,
        },
      })

      const footer2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.footer',
          start: 'top top+=30%',
          end: 'bottom bottom',
        },
      })

      footer.fromTo(
        '.footer .stripe',
        { scaleY: 1 },
        { scaleY: 0, stagger: 0.05 },
      )

      footer2.from('.footer .-p1', {
        duration: 0.8,
        autoAlpha: 0,
        y: 20,
        stagger: 0.3,
        ease: 'power2.inOut',
      })

      footer2.from(
        '.footer .-t1',
        {
          duration: 0.5,
          autoAlpha: 0,
          y: 0,
          ease: 'power2.inOut',
        },
        '-=0.7',
      )

      footer2.from(
        '.footer .line',
        {
          duration: 0.8,
          scaleX: 0,
          ease: 'power2.inOut',
        },
        '-=0.5',
      )

      footer2.from(
        '.footer .footer-contact .-p3',
        {
          duration: 1,
          autoAlpha: 0,
          y: 30,
          stagger: 0.1,
        },
        '-=0.8',
      )

      footer2.from(
        '.footer-marquee',
        { duration: 0.6, autoAlpha: 0, y: 40 },
        '-=1',
      )

      break
  }
}
