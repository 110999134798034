import store from '../store'
import Highway from '@dogstudio/highway'
import { Core, Emitter } from '../core'
import { Splits, Menu, Forms } from '../components'
import { qs, qsa, bounds } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from 'gsap'

class Default extends Highway.Renderer {
  constructor(opt = {}) {
    super(opt)
    gsap.registerPlugin(ScrollTrigger)

    this.core = new Core()
    this.currentTheme = localStorage.getItem('theme')
      ? localStorage.getItem('theme')
      : null
  }

  onEnter() {
    const { sniff, body } = store
    if (sniff.isDevice) body.classList.add('is-device')
    store.page = this.wrap.lastElementChild
  }

  onEnterCompleted() {
    this.on()
    if (this.core.ready) this.ready()
  }

  ready = () => {
    const { animations } = this.core

    ScrollTrigger.refresh()

    this.getDom()
    this.components()
    animations.in()
    animations.onScroll()
  }

  readyCompleted = () => {
    store.flags.locked = false
  }

  getDom() {
    const rect = bounds(qs('.hero'))

    this.dom = {
      deskMenu: qs('.header-menu'),
      mobMenu: qs('.mobile-menu'),
      footer: qs('.footer'),
      open: qs('.burger'),
      close: qs('.close'),
      buttons: qsa('.hero .button'),
      rect,
    }
  }

  components() {
    const { location } = this.core.render
    const { deskMenu, mobMenu, footer, open, close, buttons } = this.dom

    this.spliText = new Splits()

    this.forms = new Forms()

    this.menu = new Menu({
      deskMenu,
      mobMenu,
      footer,
      url: location.href,
      open,
      close,
    })

    buttons.forEach((el) => {
      el.addEventListener('click', this.updateTheme)
    })

    if (this.currentTheme) {
      document.documentElement.setAttribute('data-theme', this.currentTheme)

      if (this.currentTheme === 'red') {
        buttons.forEach((button) => button.classList.remove('--active'))
        buttons[0].classList.add('--active')
      }
      if (this.currentTheme === 'dark') {
        buttons.forEach((button) => button.classList.remove('--active'))
        buttons[1].classList.add('--active')
      }
      if (this.currentTheme === 'red') {
        buttons.forEach((button) => button.classList.remove('--active'))
        buttons[2].classList.add('--active')
      }
    }
  }

  updateTheme = (e) => {
    const { buttons } = this.dom
    const target = e.target
    const clasName = target.classList
    const light = clasName.contains('button-white')
    const dark = clasName.contains('button-black')
    const red = clasName.contains('button-red')

    buttons.forEach((button) => button.classList.remove('--active'))
    target.classList.add('--active')

    if (light) document.documentElement.setAttribute('data-theme', 'light')
    if (dark) document.documentElement.setAttribute('data-theme', 'dark')
    if (red) document.documentElement.setAttribute('data-theme', 'red')

    if (light) localStorage.setItem('theme', 'light')
    if (dark) localStorage.setItem('theme', 'dark')
    if (red) localStorage.setItem('theme', 'red')
  }

  tick = (e) => {
    const { rect } = this.dom

    if (store.scroll.current > rect.height) {
      gsap.set('header', { y: 0 })
    } else {
      gsap.set('header', { y: '-100%' })
    }
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('loaded', this.ready)
    Emitter.on('animateIn:ended', this.readyCompleted)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('loaded', this.ready)
    Emitter.on('animateIn:ended', this.readyCompleted)
  }

  onLeave() {
    this.off()
    this.menu && this.menu.destroy()
  }

  onLeaveCompleted() {
    ScrollTrigger.getAll().forEach((inst) => inst.kill(true))
  }
}

export default Default
