import { Helpers } from '@dogstudio/highway'
import gsap from 'gsap'
import { Emitter } from '../core'

export const animateIn = (animation) => {
  switch (animation) {
    case 'hero':
      gsap.set('.page', { autoAlpha: 1 })

      const bloom = gsap.timeline({
        paused: true,
        repeat: -1,
        repeatDelay: 1,
      })

      const collective = gsap.timeline({
        paused: true,
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
      })

      const hero = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      bloom.to('.hero .subitem-char', {
        duration: 2,
        rotate: 360,
        stagger: 0.035,
        ease: 'power4.inOut',
      })

      collective.to(
        '.--large .labels-item:first-child .char-0',
        {
          duration: 2,
          stagger: 0.5,
          rotateY: 180,
        },
        0.5,
      )

      collective.to(
        '.--small .labels-item:last-child .char-0',
        {
          duration: 2,
          stagger: 0.5,
          rotateY: 180,
        },
        0.5,
      )

      collective.to(
        '.--large .labels-item:last-child .char-0',
        {
          duration: 2,
          stagger: 0.5,
          rotateY: 180,
        },
        1.2,
      )

      collective.to(
        '.--small .labels-item:first-child .char-0',
        {
          duration: 2,
          stagger: 0.5,
          rotateY: 180,
        },
        1.2,
      )

      hero.from('.side-symbol', {
        duration: 0.6,
        y: '-100%',
        ease: 'power2.inOut',
      })
      hero.from(
        '.symbol-crop',
        { duration: 0.6, y: '100%', ease: 'power2.inOut' },
        '-=0.6',
      )

      hero.from('.side-labels', { duration: 0.8, y: '-300%' }, '-=0.5')

      hero.from('.--large .labels-item:first-child .char-0', {
        duration: 1,
        autoAlpha: 0,
        stagger: 0.15,
        rotateY: 95,
      })

      hero.from(
        '.--small .labels-item:first-child .char-0',
        {
          duration: 1,
          autoAlpha: 0,
          stagger: 0.15,
          rotateY: 95,
        },
        '-=1.4',
      )

      hero.from(
        '.--large .labels-item:last-child .char-0',
        {
          duration: 1,
          autoAlpha: 0,
          stagger: 0.15,
          rotateY: 95,
        },
        '-=2.2',
      )

      hero.from(
        '.--small .labels-item:last-child .char-0',
        {
          duration: 1,
          autoAlpha: 0,
          stagger: 0.15,
          rotateY: 95,
        },
        '-=2.2',
      )

      hero.from(
        '.hero-marquee',
        { duration: 0.8, y: '-100%', ease: 'power2.inOut' },
        '-=3',
      )
      hero.from(
        '.hero-marquee .marquee-wrap',
        { duration: 0.8, y: '100%', ease: 'power2.inOut' },
        '-=3',
      )

      hero.from(
        '.hero .button',
        { duration: 0.8, scale: 0, stagger: 0.05 },
        '-=2',
      )

      hero.from(
        '.item-char',
        {
          duration: 0.8,
          y: '100%',
          stagger: 0.05,
          ease: 'power2.inOut',
          onComplete: () => {
            gsap.set('.logo-item', { overflow: 'visible' })
            bloom.play()
            collective.play()
          },
        },
        '-=2.4',
      )

      hero.play()

      break
  }
}
