import store from '../store'
import Highway from '@dogstudio/highway'
import { Page } from '../renders'
import { Fade } from '../transitions'
import {
  Emitter,
  GlobalVRaf,
  GlobalRaf,
  Scroll,
  VScroll,
  Mouse,
  GlobalResize,
  AssetLoader,
  AnimationManager,
} from './'

let singleton = null

export default class Core {
  constructor() {
    if (!singleton) {
      singleton = this
    } else {
      return singleton
    }

    this.ready = false

    // Store
    this.store = store
    // Highway
    this.render = new Highway.Core({
      renderers: {
        page: Page,
      },
      transitions: {
        default: Fade,
        contextual: {
          fade: Fade,
        },
      },
    })
    // Global Request Animation Frame
    //this.raf = new GlobalVRaf() // When using Custom Scroll
    this.raf = new GlobalRaf() // When using Lenis
    // Global Scroll
    //this.scroll = new VScroll({ smooth: true }) // Custom Smooth
    this.scroll = new Scroll() // Lenis
    // Global Mouse
    this.mouse = new Mouse()
    // Global Resize
    this.resize = new GlobalResize()
    // Asset Loader (Needs a data file data.yml)
    this.loader = new AssetLoader()
    // Animation Manager
    this.animations = new AnimationManager()

    this.init()
  }

  init() {
    // Initialize core methods
    this.raf.on()
    this.scroll.on()
    this.mouse.on()

    this.loader.on().then((responses) => {
      this.ready = true
      Emitter.emit('loaded')
    })
  }
}
