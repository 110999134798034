import { qs, qsa, growTextarea } from '../utils'
import gsap from 'gsap'

export default class Forms {
  constructor() {
    this.dom = {
      forms: qsa('form'),
      inputs: qsa('input'),
      dmessage: qs('.message-default'),
      smessage: qs('.message-success'),
      dlabel: qs('.label-default'),
      slabel: qs('.label-success'),
      dbutton: qs('.button-default'),
      sbutton: qs('.button-success'),
    }

    growTextarea()

    this.init()
  }

  handleSubmit = (e, form) => {
    e.preventDefault()

    const formData = new FormData(form)

    fetch(form.getAttribute('action'), {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        this.updateForm(form)
      })
      .catch((error) => console.log(error))
  }

  updateForm(form) {
    const { dmessage, smessage, dbutton, sbutton, dlabel, slabel } = this.dom

    gsap.to(dlabel, { duration: 0.5, autoAlpha: 0, y: -20 })
    gsap.to(dmessage, { duration: 0.5, autoAlpha: 0, y: -20, delay: 0.2 })
    gsap.to(dbutton, { duration: 0.5, autoAlpha: 0, y: -20, delay: 0.3 })

    gsap.fromTo(
      slabel,
      { y: 20, autoAlpha: 0 },
      { duration: 0.5, autoAlpha: 1, y: 0, delay: 0.7 },
    )
    gsap.fromTo(
      smessage,
      { y: 20, autoAlpha: 0 },
      { duration: 0.5, autoAlpha: 1, y: 0, delay: 0.9 },
    )
    gsap.fromTo(
      sbutton,
      { y: 20, autoAlpha: 0 },
      { duration: 0.5, autoAlpha: 1, y: 0, delay: 1.1 },
    )

    setTimeout(() => {
      form.reset()

      gsap.to(slabel, { duration: 0.5, autoAlpha: 0, y: -20 })
      gsap.to(smessage, { duration: 0.5, autoAlpha: 0, y: -20, delay: 0.2 })
      gsap.to(sbutton, { duration: 0.5, autoAlpha: 0, y: -20, delay: 0.3 })

      gsap.fromTo(
        dlabel,
        { y: 20 },
        { duration: 0.5, autoAlpha: 1, y: 0, delay: 0.7 },
      )
      gsap.fromTo(
        dmessage,
        { y: 20 },
        { duration: 0.5, autoAlpha: 1, y: 0, delay: 0.9 },
      )
      gsap.fromTo(
        dbutton,
        { y: 20 },
        { duration: 0.5, autoAlpha: 1, y: 0, delay: 1.2 },
      )
    }, 3000)
  }

  handleError = (e, form) => {
    const required = qsa('[required]', form)

    required.forEach((item) => {
      const value = item.value.length
      const parent = item.parentNode

      if (value < 1) {
        parent.classList.add('-error')
      } else {
        parent.classList.remove('-error')
      }
    })
  }

  on() {
    const { forms, inputs, text } = this.dom
    forms.forEach((form) => {
      form.addEventListener('submit', (e) => this.handleSubmit(e, form))
    })
  }

  init() {
    this.on()
  }
}
